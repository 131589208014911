import React from 'react'

import { Platform, StyleSheet, View } from 'react-native'
import { Overlay } from 'react-native-elements'
import colors from '../styles/colors'

const SplitView = ({ isCurrentlySplit, viewList, viewDetail, onDismiss }) => {
  return (
    <View data-test-id="split-master" style={{ flexDirection: 'row', flex: 1 }}>
      {!isCurrentlySplit && (
        <View
          data-test-id="split-list"
          style={{
            flex: 1,
            flexDirection: 'column',
            borderRightColor: colors.lighterGray,
            borderRightWidth: StyleSheet.hairlineWidth
          }}
        >
          {viewList}
        </View>
      )}
      {isCurrentlySplit && (
        <Overlay
          isVisible={true}
          onBackdropPress={onDismiss}
          overlayStyle={{
            margin: 0,
            padding: 0,
            width: '100%',
            height: '100%',
            flex: 1
          }}
        >
          <View style={{ flex: 1 }}>
            <View
              style={{ flex: 1, minHeight: Platform.OS === 'ios' ? 330 : 140 }}
            >
              <View style={[{ flex: 1 }]}>{viewDetail}</View>
            </View>
          </View>
        </Overlay>
      )}
    </View>
  )
}

export default SplitView
