// libraries
import React, { useEffect, useState } from 'react'
import { View, Text } from 'react-native'
import _ from 'lodash'

import { Input, Overlay } from 'react-native-elements'

import { Avatar } from 'react-native-paper'
import Papa from 'papaparse'

import MSFESButton from '../MSFESButton'
import shared from '../../styles/shared'
import spacing from '../../styles/spacing'
import { snakeCase } from 'snake-case'
import { v4 as uuidv4 } from 'uuid'
import SingleFile from './SingleFile'
import MediaHelper from '../../libraries/mediaHelper'
import MSFESChip from '../MSFESChip'
import wrappedAlert from '../../helpers/alert'
import { WrappedSwitch } from '../EntityField'

const getEmptyField = () => {
  return { label: '', value: '', key: '', offline_id: uuidv4() }
}
export const TextArrayField = React.forwardRef((props, ref) => {
  const {
    value,
    editable = true,
    onChangeText,
    usesLabelsAndValues = false,
    isQuizField = false,
    placeholder = "Label (e.g. 'enter key')",
    buttonLabel = 'Save Option'
  } = props

  const [statefulValue, setStatefulValue] = useState(
    props.defaultValue ? props.defaultValue : []
  )

  useEffect(() => {
    setStatefulValue(value)
  }, [value])
  const [addingField, setAddingField] = useState(getEmptyField())

  const updateValue = (newValue) => {
    setStatefulValue(newValue)
    onChangeText(newValue)
  }

  const [isEditing, setIsEditing] = useState(false)

  const addField = () => {
    const value = statefulValue ? [...statefulValue] : []

    const existingMatchIndex = value.findIndex(
      (v) => v.offline_id === addingField.offline_id
    )

    if (existingMatchIndex === -1) {
      value.push(addingField)

      setAddingField(getEmptyField())

      updateValue(value)
    } else {
      value[existingMatchIndex] = addingField
      updateValue(value)

      setIsEditing(false)
    }
  }

  const editField = (field) => {
    if (!editable) {
      return
    }
    setAddingField(field)
    setIsEditing(true)
  }

  const removeField = (option) => {
    if (!editable) {
      return
    }
    const value = statefulValue.filter((v) => v !== option)

    updateValue(value)
  }

  const [uploadIsBusy, setUploadIsBusy] = useState(false)
  const onUploadIsBusyStateChanged = (newUploadIsBusyState) => {
    setUploadIsBusy(newUploadIsBusyState)
  }

  const [overlayState, setOverlayState] = useState(false)
  const showExtraOptions = () => {
    setOverlayState(true)
  }

  const reimportOptions = () => {
    MediaHelper.getMedia(MediaHelper.DocumentType).then(async ({ payload }) => {
      Papa.parse(payload.file, {
        header: true,
        complete: function (results) {
          updateValue(results.data)
          wrappedAlert('Import successful.', 'Confirm results before saving.')
        }
      })

      setOverlayState(false)
    })
  }
  const exportOptions = () => {
    let filename = 'export.csv'
    let columns = null

    let csv = Papa.unparse({ data: statefulValue, fields: columns })
    if (csv == null) return

    var blob = new Blob([csv])
    if (window.navigator.msSaveOrOpenBlob)
      // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      window.navigator.msSaveBlob(blob, filename)
    else {
      var a = window.document.createElement('a')
      a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
      a.download = filename
      document.body.appendChild(a)
      a.click() // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
      document.body.removeChild(a)
    }
  }

  return (
    <View style={{ flex: 1, flexDirection: 'row' }}>
      <View style={{ flex: 1, marginBottom: spacing.m1 }}>
        {statefulValue &&
          _.sortBy(statefulValue, (d) => d?.sort).map((valueOption, index) => {
            const avatar = valueOption.path ? (
              <Avatar.Image size={24} source={valueOption.path[0]} />
            ) : undefined

            return (
              <MSFESChip
                key={index}
                onPress={() => editField(valueOption)}
                onClose={() => removeField(valueOption)}
                style={{ marginTop: spacing.m1 }}
                avatar={avatar}
                disabled={!editable}
              >
                {valueOption.label} {valueOption.correctAnswer ? '✔︎' : ''}
              </MSFESChip>
            )
          })}
      </View>
      <View style={{ flex: 1 }}>
        {!isEditing && (
          <View style={{ marginLeft: spacing.m1, flexDirection: 'row' }}>
            <MSFESButton
              onPress={() => setIsEditing(true)}
              title="Add"
              disabled={!editable}
              containerStyle={{ flex: 1, marginRight: spacing.m1 }}
            ></MSFESButton>
            <MSFESButton
              onPress={showExtraOptions}
              disabled={uploadIsBusy || !editable}
              buttonStyle={[shared.debugOff]}
              containerStyle={[shared.debugOff]}
              title="..."
            />
          </View>
        )}
        {overlayState && (
          <Overlay
            isVisible={overlayState}
            onBackdropPress={() => setOverlayState(false)}
          >
            <MSFESButton
              onPress={exportOptions}
              buttonStyle={[shared.debugOff]}
              containerStyle={[shared.debugOff]}
              title="Export Options to CSV"
            />
            <MSFESButton
              onPress={reimportOptions}
              buttonStyle={[shared.debugOff]}
              containerStyle={[shared.debugOff]}
              title="Re-Import Options from CSV"
            />
          </Overlay>
        )}

        <Overlay
          isVisible={isEditing}
          onBackdropPress={() => setIsEditing(false)}
        >
          <View>
            <Input
              onChangeText={(newValue) => {
                setAddingField((field) => {
                  // if it's value significant, return the object
                  // if it's not, snake case the label and put it in key and value.
                  return usesLabelsAndValues
                    ? {
                        ...getEmptyField(),
                        ...field,
                        ...{ label: newValue, key: snakeCase(newValue) }
                      }
                    : {
                        ...getEmptyField(),
                        ...field,
                        ...{
                          label: newValue,
                          value: snakeCase(newValue),
                          key: snakeCase(newValue)
                        }
                      }
                })
              }}
              placeholder={placeholder}
              value={addingField.label}
              style={{ flex: 1 }}
            />
            {usesLabelsAndValues && (
              <>
                <Input
                  onChangeText={(newValue) => {
                    setAddingField((field) => {
                      return { ...field, key: newValue }
                    })
                  }}
                  placeholder="Key (e.g. great_job)"
                  value={addingField.key}
                  style={{ flex: 1 }}
                />
                <Input
                  onChangeText={(newValue) => {
                    setAddingField((field) => {
                      return { ...field, value: newValue }
                    })
                  }}
                  placeholder="Value (e.g. 5)"
                  value={addingField.value}
                  style={{ flex: 1 }}
                />
                <Input
                  onChangeText={(newValue) => {
                    setAddingField((field) => {
                      return { ...field, sort: newValue }
                    })
                  }}
                  placeholder="Sort Order"
                  value={addingField.sort}
                  style={{ flex: 1 }}
                />
                {!isQuizField && (
                  <View>
                    <Text>Default Value</Text>
                    <WrappedSwitch
                      onValueChange={(newValue) => {
                        setAddingField((field) => {
                          return { ...field, defaultValue: newValue }
                        })
                      }}
                      value={addingField.defaultValue}
                      editable={true}
                    />
                  </View>
                )}
                {isQuizField && (
                  <View>
                    <Text>Correct Answer</Text>
                    <WrappedSwitch
                      onValueChange={(newValue) => {
                        setAddingField((field) => {
                          return { ...field, correctAnswer: newValue }
                        })
                      }}
                      value={addingField.correctAnswer}
                      editable={true}
                    />
                  </View>
                )}

                <SingleFile
                  value={addingField.image}
                  onBusyStateChanged={onUploadIsBusyStateChanged}
                  pathValue={addingField.path}
                  onChangeText={(newValue, newPaths) => {
                    setAddingField((field) => {
                      return { ...field, image: newValue, path: newPaths }
                    })
                  }}
                  data={{
                    field: {
                      field_data: {
                        params: {
                          fileType: MediaHelper.ImageType,
                          allowMultipleUploads: false,
                          canSelectFromLibrary: false
                        }
                      }
                    }
                  }}
                  isRestricted={false}
                />
              </>
            )}
            <MSFESButton
              onPress={addField}
              disabled={uploadIsBusy}
              buttonStyle={[shared.debugOff]}
              containerStyle={[shared.debugOff]}
              type="add"
              title={buttonLabel}
            />
          </View>
        </Overlay>
      </View>
    </View>
  )
})
