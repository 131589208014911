import React, { useEffect, useMemo, useState } from 'react'
import CurrentlyNoItems from '../../components/CurrentlyNoItems'
import _ from 'lodash'
import { sortListWithEntityType } from '../../libraries/entityTools'
import { DataTable } from 'react-native-paper'

import { useSelector } from 'react-redux'
import {
  FlatList,
  RefreshControl,
  ScrollView,
  View,
  Text,
  StyleSheet
} from 'react-native'
import colors from '../../styles/colors'
import spacing from '../../styles/spacing'
import { currentlyNoTemplateDefaults } from '../CategoryViewScreen'
import { flattenEntity } from '../../helpers/entity'
import { viewMode } from '../EntityAddComponent'
import useBetterLinkTo from '../../useBetterLinkTo'
import { IsGenericEntityIdentifier } from '../../components/GridItemButton'
import useTableSort from '../../hooks/useTableSort'
import MaskInput, { createNumberMask } from 'react-native-mask-input'
import { DataTableCell } from './DataTableCell'
export const cellStyle = {
  minWidth: 150,
  borderRightColor: colors.lightestGray,
  paddingLeft: spacing.m1,
  borderRightWidth: StyleSheet.hairlineWidth,
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1
}

export const headerCellStyle = {
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1
}

export const formatTotalCost = (value) => {
  if (value) {
    const dollar = createNumberMask({
      prefix: ['$', ''],
      delimiter: ',',
      separator: '.',
      precision: 2
    })

    return (
      <MaskInput
        value={value}
        mask={dollar}
        editable={false}
        style={{ textAlign: 'center' }}
      />
    )
  } else {
    return null
  }
}

export const sortColumnHeaders = (columnHeaders, isForCustomReport) => {
  return _.chain(columnHeaders ?? [])
    .sortBy((f) => {
      if (f.field_data?.table_sort_order || isForCustomReport) {
        const key = _.get(f, 'field_data.table_sort_order', 10000) + '_' + f.id
        return key
      } else {
        return _.get(f, 'field_data.sort_order', 999)
      }
    })
    .sortBy((f) => _.get(f, 'field_data.page', 1))
    .value()
}
const DataTableWrapper = ({
  sourceKey,
  shouldSplit,
  entityType,
  filterToFields,
  refreshing,
  onRefresh,
  loadMoreItems,
  splitviewNavigate,
  isForCustomReport = false,
  forceShowLabelInTable = false,
  canNavigate = true,
  children
}) => {
  const linkTo = useBetterLinkTo()
  const [columnHeaders, updateColumnHeaders] = useState([])

  //TODO: refactor out this common functionality that is also in datalistwrapper.
  const entityListWrapper = useSelector((state) => {
    const entityListWrapper = entityType
      ? _.get(state.entities, sourceKey, {
          data: null,
          meta: null,
          hasSeenData: false
        })
      : { data: null, meta: null, hasSeenData: false }

    entityListWrapper.data = sortListWithEntityType(
      entityListWrapper.data,
      entityType
    )
    return entityListWrapper
  })

  useEffect(() => {
    if (entityType) {
      const headersToUse = entityType.fields.filter((f) => {
        if (isForCustomReport) {
          return filterToFields
            ? filterToFields.find(
                (filterField) =>
                  filterField.field_data?.property == f.field_data?.property
              )
            : true
        } else {
          return _.get(f, 'field_data.flags.tableView', false)
        }
      })

      updateColumnHeaders(headersToUse)
    }
  }, [entityType, filterToFields, isForCustomReport])

  const { data: entityListItems } = entityListWrapper

  const showLabelInTable =
    _.get(entityType, 'object_data.show_label_table_view', false) ||
    forceShowLabelInTable

  const showAssigneeInTable =
    !!_.get(entityType, 'object_data.show_assignee', false) ||
    entityType?.is_task

  const sortedColumnHeaders = sortColumnHeaders(
    columnHeaders,
    isForCustomReport
  )

  const currentlyNoLabel = useMemo(() => {
    return currentlyNoTemplateDefaults({ label: entityType?.label })
  }, [entityType])

  const [sort, activeSort, sortDirection] = useTableSort()

  const resortedEntityListItems = useMemo(() => {
    if (!sort) {
      return entityListItems
    } else {
      let sorted = _.sortBy(entityListItems, (el) => {
        const flattened = flattenEntity(el, true)

        return ('' + flattened[activeSort]).toLowerCase()
      })

      if (sortDirection == 'descending') {
        sorted = sorted.reverse()
      }
      return sorted
    }
  }, [entityListItems, entityListItems.length, activeSort, sortDirection])

  return (
    <>
      {!resortedEntityListItems.length &&
        (refreshing ? null : <CurrentlyNoItems label={currentlyNoLabel} />)}
      {!!resortedEntityListItems.length && (
        <ScrollView
          horizontal={true}
          scrollEnabled={true}
          contentContainerStyle={{ flexDirection: 'column' }}
        >
          <View style={{ flexDirection: 'column', flex: 1 }}>
            {children}
            <DataTable style={{ flex: 1 }}>
              <DataTable.Header>
                {showLabelInTable && (
                  <DataTable.Title
                    key={'label'}
                    style={cellStyle}
                    sortDirection={activeSort == 'label' ? sortDirection : null}
                    onPress={sort('label')}
                  >
                    Label
                  </DataTable.Title>
                )}
                {showAssigneeInTable && (
                  <DataTable.Title
                    key={'assignee'}
                    style={cellStyle}
                    onPress={sort('assignee')}
                    sortDirection={
                      activeSort == 'assignee' ? sortDirection : null
                    }
                  >
                    Assignee
                  </DataTable.Title>
                )}
                {sortedColumnHeaders?.map((columnHeaderField, index) => {
                  return (
                    <DataTable.Title
                      key={index}
                      style={cellStyle}
                      onPress={sort(columnHeaderField.field_data?.property)}
                      sortDirection={
                        activeSort == columnHeaderField.field_data?.property
                          ? sortDirection
                          : null
                      }
                    >
                      {columnHeaderField.field_data.title}
                    </DataTable.Title>
                  )
                })}
              </DataTable.Header>
              <View style={{ flex: 1 }} key={'list-container'}>
                <FlatList
                  numColumns={1}
                  refreshControl={
                    <RefreshControl
                      refreshing={refreshing}
                      onRefresh={onRefresh}
                    />
                  }
                  /* Must not have flex to scroll on mobile device. */
                  contentContainerStyle={{ paddingBottom: 30 }}
                  onEndReached={loadMoreItems}
                  onEndReachedThreshold={1}
                  keyExtractor={(item) => item.id.toString()}
                  data={resortedEntityListItems}
                  renderItem={({ item, index: rowIndex }) => {
                    const flattened = flattenEntity(item, true)

                    const nonFieldColumnsAlarmColorStyle = flattened
                      ._alarmStateColors?.length
                      ? { color: flattened._alarmStateColors[0] }
                      : null
                    return (
                      <DataTable.Row
                        key={rowIndex}
                        style={{
                          backgroundColor:
                            rowIndex % 2 ? 'transparent' : '#f1f1f1'
                        }}
                        onPress={() =>
                          canNavigate
                            ? shouldSplit
                              ? splitviewNavigate(item, viewMode)
                              : linkTo(flattened.uri)
                            : undefined
                        }
                      >
                        {showLabelInTable && (
                          <DataTable.Cell key={'label'} style={cellStyle}>
                            <Text style={nonFieldColumnsAlarmColorStyle}>
                              {flattened.display_name}
                            </Text>{' '}
                            {!item.tenant_id && <IsGenericEntityIdentifier />}
                          </DataTable.Cell>
                        )}
                        {showAssigneeInTable && (
                          <DataTable.Cell key={'assignee'} style={cellStyle}>
                            <Text style={nonFieldColumnsAlarmColorStyle}>
                              {flattened.assignees}
                            </Text>
                          </DataTable.Cell>
                        )}
                        {sortedColumnHeaders?.map(
                          (columnHeaderField, columnIndex) => (
                            <DataTableCell
                              key={columnIndex}
                              columnHeaderField={columnHeaderField}
                              entity={flattened}
                              cellStyle={cellStyle}
                            />
                          )
                        )}
                      </DataTable.Row>
                    )
                  }}
                />
              </View>
            </DataTable>
          </View>
        </ScrollView>
      )}
    </>
  )
}

export default DataTableWrapper
